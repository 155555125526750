import { useMemo } from 'react';
import { ReferentDto } from '@hg-aka-prml/tapas-common';

const useSelectedReferents = (allReferents: ReferentDto[], selectedReferentIds: string[]) => useMemo(() => {
  if (!allReferents) {
    return [];
  };

  return allReferents.filter(
    (referent: ReferentDto): boolean => {
      return selectedReferentIds.indexOf(referent.externalId.toString()) > -1;
    },
  );
}, [selectedReferentIds, allReferents]);

export default useSelectedReferents;

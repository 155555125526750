import { AwsClient } from 'aws4fetch';
import { Auth } from 'aws-amplify';

declare global {
  interface Window {
    __TAPAS_AWSCLIENT__: AwsClient;
  }
}

export async function initAwsClient(): Promise<void> {
  const credentials = await Auth.currentUserCredentials();

  if (credentials.authenticated) {
    window.__TAPAS_AWSCLIENT__ = new AwsClient({
      accessKeyId: credentials.accessKeyId,
      secretAccessKey: credentials.secretAccessKey,
      sessionToken: credentials.sessionToken,
      region: 'eu-central-1',
      service: 'execute-api'
    });
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  else if ((credentials as any).code === 'NotAuthorizedException') {
    // Probably an internal property as the interface ICredentials does not expose it..
    // Should be equivalent to !credentials.authenticated
    throw new Error('Only authenticated users can acquire the aws client.');
  } else {
    // If the user is not logged it, we can't intialize the AwsClient
    throw new Error('Only authenticated users can acquire the aws client.');
  }
};

export async function deleteAwsClient(): Promise<void> {
  delete window.__TAPAS_AWSCLIENT__;
};

/* Returns a singleton aws client instance that can be used to communicate with the API Gateway.
 * Throws if the user is not logged in.
 */
export async function getAwsClient(): Promise<AwsClient> {
  if (!window.__TAPAS_AWSCLIENT__) {
    await initAwsClient();
  }
  return window.__TAPAS_AWSCLIENT__;
}

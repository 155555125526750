import {
  CalendarEntryDto,
  BusinessDivision,
  CombinedStatus,
  InquiryAnswerDto,
} from '@hg-aka-prml/tapas-common';
import { ApiService } from 'src/api/service';
import { AsyncProps } from 'react-async';
import { IApiService } from 'src/api/service.interface';
import { arrayMapper } from '@daniel-faber/json-ts';
import { ZoneId, ZonedDateTime, DateTimeFormatter, Instant } from '@js-joda/core';

export type CalendarEvent = {
  title: string;
  bookingNumber: string | undefined;
  businessDivision: BusinessDivision | undefined;
  contactPerson: string | undefined;
  location: string | undefined;
  locationId: string | undefined;
  start: string;
  end: string;
  resourceId: number;
  customer: string | undefined;
  customerNumber: string | undefined;
  region: string | undefined;
  status: CombinedStatus;
  inquiryAnswer: InquiryAnswerDto | undefined;
  blockingCreationDate: Instant | undefined | null;
};

export const loadCalendarEntries = async <T>(
  props: AsyncProps<T>,
  { signal }: AbortController,
): Promise<Array<CalendarEvent>> => {
  const api: IApiService = ApiService;
  const calendarEntries = await api.get(
    `/calendarentries/filtered`,
    arrayMapper(CalendarEntryDto.fromJSON),
    { signal },
    {
      params: {
        referentExternalId: props.id,
        startTime: props.calendarQueryTimeFrame.activeStart.toISOString(), // Backend expects instants
        endTime: props.calendarQueryTimeFrame.activeEnd.toISOString(),
      },
    },
  );

  const events = calendarEntries.map(entry => {
    return entry.parts.map(part => {
      // We pretend that the our local times are
      // UTC times. That in combination with
      // Fullcalendars timeZone="UTC" option seems
      // the only way to ensure that FullCalendar does
      // not translate the event times into the
      // user's current timezone (We don't want that for now).
      const formatter = DateTimeFormatter.ofPattern(
        "yyyy-MM-dd'T'HH:mm:00.000'Z'",
      );
      const start = ZonedDateTime.ofInstant(
        part.startTime,
        ZoneId.of('Europe/Berlin'),
      ).format(formatter);
      const end = ZonedDateTime.ofInstant(
        part.endTime,
        ZoneId.of('Europe/Berlin'),
      ).format(formatter);

      return {
        title: entry.title,
        bookingNumber: entry.bookingNumber,
        businessDivision: entry.businessDivision,
        contactPerson: entry.contactPerson,
        location: entry.location,
        locationId: entry.locationId,
        start,
        end,
        resourceId: props.id,
        customer: entry.customer,
        customerNumber: entry.customerNumber,
        region: entry.region,
        status: entry.status,
        inquiryAnswer: entry.inquiryAnswer,
        blockingCreationDate: entry.blockingCreationDate,
      };
    });
  });
  return events.flat();
};

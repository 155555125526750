import { IApiService } from './service.interface';
import { JsonMapper } from '@daniel-faber/json-ts';
import { staticImplements } from '../types/helpers';
import { ExpiredTokenException, ValidationErrors } from '../types/custom-error-types';
import { ValidationError } from 'class-validator';
import { getAwsClient } from './aws-client';

const host = `${process.env.REACT_APP_API_URL || ''}`;


// How to define static property in TypeScript interface
// https://stackoverflow.com/questions/13955157/how-to-define-static-property-in-typescript-interface
@staticImplements<IApiService>()
export class ApiService {
  public static async get<T>(
    path: string,
    mapper: JsonMapper<T>,
    fetchOptions: RequestInit = {},
    options?: { params: { [prop: string]: string } },
  ): Promise<T> {
    if (!host) {
      throw new Error('REACT_APP_API_URL env variable is not defined!');
    }
    let body;
    let response;
    try {
      if (path.indexOf('/') === 0) {
        path = path.substring(1);
      }
      const url = new URL(`${host}/${path}`);
      if (options && options.params) {
        url.search = new URLSearchParams(options.params).toString();
      }
      const awsClient = await getAwsClient();
      response = await awsClient.fetch(
        url.toString(),
        {
          aws: {
            appendSessionToken: false,
            allHeaders: false,
            sessionToken: awsClient.sessionToken
          },
          ...fetchOptions
        }
      );
      body = await response.json();
    } catch (error) {
        // E.g. connection error or perhaps AbortError if signal in fetchOptions is given
        error.message = `Couldn't fetch data from URL ${host}/${path}. Error was: ${error.message}`;
        // re-throw
        throw error;
    }

    if (response.ok) {
      return mapper(body);
    } else {
      // Deal with HTTP Errors 4xx, 5xx (Like "The security token included in the request is expired", etc.)
      const errorType = response.headers.get('x-amzn-errortype');
      if (response.status === 403 && errorType !== null && errorType === 'ExpiredTokenException') {
        // Throw custom error that is dealed with separately in React's ErrorBoundary ..
        throw new ExpiredTokenException();
      }

      throw new Error(body.message);
    }
  }

  public static async post<T, R>(
    path: string,
    data: T,
    mapper?: JsonMapper<R>,
  ): Promise<R | void> {
    if (!host) {
      throw new Error('REACT_APP_API_URL env variable is not defined!');
    }
    let response;
    try {
      if (path.startsWith('/')) {
        path = path.substring(1);
      }
      const url = `${host}/${path}`;
      let payload;

      try {
        payload = JSON.stringify(data);
      } catch (error) {
        return;
      }

      const options = {
        method: 'post',
        body: payload,
        headers: {
          Accept: 'application/json, */*',
          'Content-Type': 'application/json',
        }
      };

      const awsClient = await getAwsClient();
      response = await awsClient.fetch(
        url.toString(),
        {
          aws: {
            appendSessionToken: false,
            allHeaders: false,
            sessionToken: awsClient.sessionToken
          },
          ...options
        }
      );
    } catch (error) {
      throw new Error(error.message);
    }

    const body = await response.json();
    if (response.ok) {
      if (mapper) {
        return mapper(body);
      }
      return;
    }

    if (response.status === 400) {
      // user error
      const error = new ValidationErrors<ValidationError>();
      error.push(...(body.message || []));
      throw error;
    } else {
      throw new Error(body.message);
    }
  }
}

import { AsyncProps } from 'react-async';
import {
  CalendarEvent,
  loadCalendarEntries,
} from 'src/api/queries/calendar-entries';
import { ReferentDto } from '@hg-aka-prml/tapas-common';

export type CalendarEvents = {
  [referentId: string]: Array<CalendarEvent>;
};

export type CalendarQueryTimeFrame = {
  activeStart: Date;
  activeEnd: Date;
};

export const loadReferentsAndCalendarEntries = async (
  props: AsyncProps<CalendarEvents>,
  controller: AbortController,
): Promise<CalendarEvents> => {
  const referents: ReferentDto[] = props.referents;
  const calendarQueryTimeFrame: CalendarQueryTimeFrame =
    props.calendarQueryTimeFrame;

  const calendarEvents: CalendarEvents = {};
  await Promise.all(
    referents.map(async referent => {
      calendarEvents[referent.externalId] = await loadCalendarEntries(
        {
          calendarQueryTimeFrame,
          id: referent.externalId,
        },
        controller,
      );
    }),
  );
  return calendarEvents;
};

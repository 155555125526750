import React, { Component } from 'react';
import { Popover, withStyles, Theme } from '@material-ui/core';
import {
  eventColorRequested,
  eventColorBlocked,
  eventColorAcceptedValid,
  eventColorRejectedValid,
  eventColorRequestedAgain,
  eventColorManuell
} from 'src/calendarComponentLib/styles';

type LegendProps = {
  classes: any;
};

type LegendState = {
  anchorEl: HTMLElement | null;
  modalOpen: boolean;
};

type StyleProps = {};

const styles = (theme: Theme): StyleProps => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(3),
    borderRadius: 0,
    lineHeight: 1.3,
  },
  legendDot: {
    float: 'left',
    width: '15px',
    height: '15px',
    marginRight: '5px',
    borderRadius: '15px',
  },
  legendDotRequested: {
    background: eventColorRequested,
  },
  legendDotBlocked: {
    background: eventColorBlocked,
  },
  legendDotRejected: {
    background: eventColorRejectedValid
  },
  legendDotAccepted: {
    background: eventColorAcceptedValid
  },
  legendDotRequestedAgain: {
    background: eventColorRequestedAgain
  },
  legendDotManuell: {
    background: eventColorManuell
  },
});

class HeaderButtonLegend extends Component<LegendProps, LegendState> {
  state = {
    anchorEl: null,
    modalOpen: false,
  };

  handlePopoverOpen = (event: any): void => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePopoverClose = (): void => {
    this.setState({ anchorEl: null });
  };

  render(): JSX.Element {
    const open = Boolean(this.state.anchorEl);
    const { classes } = this.props;
    return (
      <>
        <button
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={this.handlePopoverOpen}
          onMouseLeave={this.handlePopoverClose}
          type="button"
          className="fc-button fc-button-primary"
        >
          ?
        </button>
        <Popover
          className={classes.popover}
          classes={{ paper: classes.paper }}
          open={open}
          anchorEl={this.state.anchorEl}
          onClose={this.handlePopoverClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          disableRestoreFocus
        >
          <div className={ `${ classes.legendDotRequested } ${ classes.legendDot }` } />
          Termin angefragt
          <br />
          <div className={`${classes.legendDotBlocked} ${classes.legendDot}`} />
          Termin bestätigt (ohne Terminanfrage)
          <br />
          <div className={`${classes.legendDotAccepted} ${classes.legendDot}`} />
          Termin bestätigt (Terminanfrage)
          <br />
          <div className={`${classes.legendDotRejected} ${classes.legendDot}`} />
          Termin abgelehnt (Terminanfrage)
          <br />
          <div className={`${classes.legendDotRequestedAgain} ${classes.legendDot}`} />
          Geänderte Terminanfrage
          <br />
          <div className={`${classes.legendDotManuell} ${classes.legendDot}`} />
          Manuell abgestimmter Termin
          <br />
        </Popover>
      </>
    );
  }
}

export default withStyles(styles)(HeaderButtonLegend);

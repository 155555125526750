import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import withAuth, { WithAuthProps } from 'src/containers/withAuth';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Button } from '@material-ui/core';

const useStyles = makeStyles({
  header: {
    textAlign: 'center',
    color: '#292900',
    position: 'relative',
    '& img': {
      width: '100%',
      minHeight: '150px',
    },
    '& h3': {
      position: 'absolute',
      top: '30px',
      left: '240px',
      color: '#FFFFFF',
    },
    '& .logo': {
      position: 'absolute',
      backgroundImage: 'url(HALogo.png)',
      width: '135px',
      height: '28px',
      top: '40px',
      left: '40px',
    },
    '& .btn-avatar': {
      position: 'absolute',
      top: '20px',
      right: '40px',
    },
    '& .signIn': {
      position: 'absolute',
      top: '40px',
      right: '40px',
      backgroundColor: '#000000',
      padding: '5px',
      textAlign: 'right' as const,
    },
  },
});

function Header(props: WithAuthProps): JSX.Element {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleLogout = (): void => {
    setAnchorEl(null);
    props.signOut();
  };

  const ITEM_HEIGHT = 48;

  return (
    <header className={classes.header}>
      <img alt="" src="HeroWelcome.jpg" />
      <div className="logo" />
      <h3>Trainerkapazitäten</h3>
      {props.authStatus === 'notAuthenticated' && (
        <Button
          onClick={props.signIn}
          size="large"
          variant="contained"
          color="primary"
          className="signIn"
        >
          Sign In
        </Button>
      )}
      {props.authStatus === 'signedIn' && (
        <>
          <IconButton className="btn-avatar" onClick={handleClick}>
            <Avatar />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            open={isMenuOpen}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: 200,
              },
              square: true,
            }}
          >
            <MenuItem onClick={handleLogout}>Log Out</MenuItem>
          </Menu>
        </>
      )}
    </header>
  );
}

export default withAuth(Header);

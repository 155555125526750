import { ApiService } from "./service";

export function sendInfoLogMessage(
  message: string,
  source?: string,
): Promise<void> {
  return sendLog('INFO', message, source);
}

export function sendWarnLogMessage(
  message: string,
  source?: string,
): Promise<void> {
  return sendLog('WARN', message, source);
}

export function sendErrorLogMessage(
  message: string,
  source?: string,
): Promise<void> {
  return sendLog('ERROR', message, source);
}

async function sendLog(
  level: 'INFO' | 'WARN' | 'ERROR',
  message: string,
  source?: string,
): Promise<void> {
  try {
    const body = { level, message, source };
    await ApiService.post('/clientlogging', body)
  } catch (e) {
    console.error('failed to send log to server', e);
  }
}

import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Calendar } from '@fullcalendar/core/Calendar';

import HeaderButtonLegend from './HeaderButtonLegend';
import HeaderButtonCalendar from './HeaderButtonCalendar';

interface HeaderProps extends RouteComponentProps {
  api: Calendar | undefined;
  stepHandler: Function;
  changeViewHandler: Function;
  activeView: string;
};

class CalendarHeader extends Component<HeaderProps> {
  getTimeframeButtonClass = (name: string): string => {
    let className = 'timeframe-select-button';
    if (name === this.props.activeView) {
      className += ' active';
    }

    return className;
  };

  handleOnClickToday = () => {
    const search = new URLSearchParams(this.props.location.search);
    const today = new Date();
    const formatted = `${today.getFullYear()}-${('0' + (today.getMonth() + 1)).slice(-2)}-${('0' + today.getDate()).slice(-2)}`;
    search.set('date', formatted);
    this.props.history.push({ search: search.toString() })
    this.props.api?.today()
  }

  render() {
    return (
      <>
        <div className="fc-toolbar fc-header-toolbar">
          <div className="fc-left timeframe-select">
            <div className="fc-button-group">
              <span
                className={this.getTimeframeButtonClass('resourceTimeGridDay')}
                onClick={() =>
                  this.props.changeViewHandler('resourceTimeGridDay')
                }
              >
                Tag
              </span>
              <span
                className={this.getTimeframeButtonClass('resourceTimeGridWeek')}
                onClick={() =>
                  this.props.changeViewHandler('resourceTimeGridWeek')
                }
              >
                Woche
              </span>
              <span
                className={this.getTimeframeButtonClass(
                  'resourceTimeGridMonth',
                )}
                onClick={() =>
                  this.props.changeViewHandler('resourceTimeGridMonth')
                }
              >
                Monat
              </span>
            </div>
          </div>
        </div>
        <div className="fc-toolbar fc-header-toolbar">
          <div className="fc-left">
            <h2 className="month-title">{this.props.api?.view.title}</h2>
          </div>
          <div className="fc-right">
            <HeaderButtonLegend />
            <button
              type="button"
              className="fc-customPrev-button fc-button fc-button-primary"
              aria-label="customPrev"
              onClick={() => this.props.stepHandler('PREV')}
            >
              <span className="fc-icon fc-icon-chevron-left"></span>
            </button>
            <span
              className="todayButton"
              onClick={this.handleOnClickToday}
            >
              Heute
            </span>
            <button
              type="button"
              className="fc-customNext-button fc-button fc-button-primary"
              aria-label="customNext"
              onClick={() => this.props.stepHandler('NEXT')}
            >
              <span className="fc-icon fc-icon-chevron-right"></span>
            </button>
            <HeaderButtonCalendar api={this.props.api} />
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(CalendarHeader);

import React, { useRef, useState, useMemo } from 'react';
import FullCalendar from '@fullcalendar/react';
import { View } from '@fullcalendar/core';
import deLocale from '@fullcalendar/core/locales/de';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import { Typography } from '@material-ui/core';
import { useAsync } from 'react-async';
import { useLocation, useHistory } from 'react-router-dom';
import { CalendarEvent } from '../api/queries/calendar-entries';
import { LocalDate, LocalDateTime, Year, ZoneOffset, LocalTime } from '@js-joda/core';
import { ReferentDto } from '@hg-aka-prml/tapas-common';
import { calculateGermanHolidays } from 'src/holidays';
import {
  useStyles,
  StyleProps,
  usePopoverStyles,
} from 'src/calendarComponentLib/styles';
import {
  CalendarEvents,
  loadReferentsAndCalendarEntries,
} from 'src/calendarComponentLib/loader';
import { formatColumnHeader } from 'src/calendarComponentLib/format';
import {
  views,
  dayRenderer,
  dayClickHandler,
  eventRender,
} from 'src/calendarComponentLib/elements';
import { registerForUpdateUponScroll } from 'src/calendarComponentLib/scroll';
import CalendarHeader from './CalendarHeader/CalendarHeader';
import useSelectedReferents from 'src/hooks/useSelectedReferents';
import useQueryIntervalFromDate from 'src/hooks/useQueryIntervalFromDate';

const COLUMN_MIN_WIDTH = 25;

type Props = {
  allReferents: ReferentDto[];
  selectedReferentIds: string[];
};

const ReferentResourceGrid = ({ selectedReferentIds, allReferents }: Props): JSX.Element => {
  const fc = useRef<FullCalendar>(null);

  const referents: ReferentDto[] = useSelectedReferents(allReferents, selectedReferentIds);

  // Scroll handling
  const calendarViewScrollLeft = useRef<number | null>(null);

  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  let defaultDate: Date;
  const date = queryParams.get('date');
  let activeView = queryParams.get('activeView');
  const validViews = ['resourceTimeGridMonth', 'resourceTimeGridWeek', 'resourceTimeGridDay'];
  if (!activeView || !validViews.includes(activeView)) {
    activeView = validViews[0];
  }
  if (date) {
    const localDate = LocalDate.parse(date);
    defaultDate = new Date(LocalDateTime.of(localDate, LocalTime.of(0, 0)).toEpochSecond(ZoneOffset.UTC) * 1000);
  } else {
    defaultDate = new Date();
  }

  const fcApi = fc.current?.getApi();
  const calendarQueryTimeFrame = useQueryIntervalFromDate(fcApi, date);

  // Adjust width special case for month display
  let timeGridWidth = '100%';
  switch (activeView) {
    case 'resourceTimeGridMonth':
      timeGridWidth = `${Math.max(referents.length, 2) *
        31 *
        COLUMN_MIN_WIDTH}px`;
      break;
    default:
  }

  // Fetch styles
  const classes = useStyles({ timeGridWidth } as StyleProps);
  const classesPopover = usePopoverStyles();

  // Handle holiday display
  const holidays = useMemo(() => {
    const year = Year.of(calendarQueryTimeFrame.activeStart.getFullYear());
    return calculateGermanHolidays(year);
  }, [calendarQueryTimeFrame.activeStart]);

  let holidayPopoverContent = null;
  const [holidayPopoverAnchorDate, setHolidayPopoverAnchorDate] = useState<
    string | null
  >(null);
  const holidayPopoverOpen = Boolean(holidayPopoverAnchorDate);
  if (holidayPopoverAnchorDate) {
    const date = LocalDate.parse(holidayPopoverAnchorDate);
    const h = holidays.getAt(date);
    if (h.length > 0) {
      holidayPopoverContent = h.map((holiday, index) => {
        return (
          <React.Fragment key={index}>
            <span>
              <b>{holiday.name}</b>
            </span>
            <br />
            {holiday.state.length > 0 && (
              <>
                <span>Regionaler Feier- oder Gedenktag in:</span>
                <br />
                <span>{holiday.state.join(', ')}</span>
              </>
            )}
          </React.Fragment>
        );
      });
    }
  }

  function handleHolidayPopoverClose(): void {
    setHolidayPopoverAnchorDate(null);
  }

  // Load calendar entries for selected referents
  const { data, error } = useAsync<CalendarEvents>(
    loadReferentsAndCalendarEntries,
    {
      watchFn: (props, prevProps) => {
        return JSON.stringify(props) !== JSON.stringify(prevProps);
      },
      referents,
      calendarQueryTimeFrame,
    },
  );

  if (error) {
    // Let React's Errorboundary deal with the error.
    throw error;
  }

  let events;
  if (data) {
    events = Object.keys(data).reduce<Array<CalendarEvent>>(
      (acc, key) => acc.concat(...data[key]),
      [],
    );
  }

  let referentResources: { id: string; title: string }[] = [];
  if (referents) {
    referentResources = referents.map(el => ({
      id: el.externalId.toString(),
      title: `${el.firstname} ${el.lastname}`,
    }));
    if (referentResources.length === 0) {
      // Add a dummy resource to have the calendar layout rendered
      referentResources.push({ id: '', title: '' });
    }
  }

  // Calendar paging function
  function calendarStep(direction: 'PREV' | 'NEXT'): void {
    if (fc.current) {
      const fcApi = fc.current.getApi();

      // Move one unit forward (e.g. one month or one week)
      if (direction === 'PREV') {
        fcApi.prev();
      } else {
        fcApi.next();
      }

      const search = new URLSearchParams(location.search);
      const date = fcApi.getDate(); // calendar is configured to return UTC time
      const localDate = LocalDateTime.ofEpochSecond(date.valueOf() / 1000, ZoneOffset.UTC);
      search.set('date', localDate.toLocalDate().toString());
      history.replace({ search: search.toString() })
    }
  }

  // Handler to change display interval
  function changeView(viewName: string): void {
    if (fc.current) {
      const fcApi = fc.current.getApi();
      const search = new URLSearchParams(location.search);
      search.set('activeView', viewName);
      history.replace({ search: search.toString() })
      fcApi.changeView(viewName);
    }
  }

  return (
    <>
      <div id="eventInfoContainer"></div>
      <CalendarHeader
        api={fc.current?.getApi()}
        stepHandler={calendarStep}
        changeViewHandler={changeView}
        activeView={activeView}
      />
      <Grid container spacing={6}>
        <Grid item xs={12} className={classes.timeGridStyles}>
          <FullCalendar
            ref={fc}
            slotLabelFormat={{
              minute: '2-digit',
              hour: '2-digit',
            }}
            height="auto"
            datesAboveResources={true}
            datesRender={(arg: {
              view: View;
              date: Date;
              el: HTMLElement;
            }): void => {
              registerForUpdateUponScroll(calendarViewScrollLeft, arg.el);
              if (calendarViewScrollLeft?.current) {
                arg.el.scrollLeft = calendarViewScrollLeft.current;
              }
            }}
            defaultDate={defaultDate}
            schedulerLicenseKey="0262466987-fcs-1583222891"
            defaultView={activeView}
            plugins={[resourceTimeGridPlugin]}
            locales={[deLocale]}
            locale="de"
            // timezone setting does not work like one would assume:
            // https://stackoverflow.com/questions/48853015/fullcalendar-timezone-does-not-work
            // When using UTC (as opposed to 'local') no tz conversion will take place (which is what
            // we want here).
            timeZone="UTC"
            header={{
              left: '',
              center: '',
              right: '',
            }}
            minTime="08:00"
            maxTime="20:00"
            views={views()}
            columnHeaderHtml={(date): string => {
              return formatColumnHeader(date, 'eee d', holidays);
            }}
            events={events}
            eventRender={eventRender(referents, activeView)}
            resources={referentResources}
            dayRender={dayRenderer(holidays)}
            navLinks={true}
            navLinkDayClick={dayClickHandler(
              setHolidayPopoverAnchorDate,
              holidays,
            )}
          />
        </Grid>
      </Grid>
      <Popover
        open={holidayPopoverOpen}
        anchorEl={(element: Element): Element => {
          if (!holidayPopoverAnchorDate) {
            return document.body;
          }
          const selector = `[data-goto="{\\"date\\":\\"${holidayPopoverAnchorDate}\\",\\"type\\":\\"day\\"}"]`;
          const el = document.querySelector(selector);
          if (!el) {
            throw new Error('Anchor element not found');
          }
          return el;
        }}
        onClose={handleHolidayPopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography className={classesPopover.popoverContent}>
          {holidayPopoverContent}
        </Typography>
      </Popover>
    </>
  );
};

export default ReferentResourceGrid;

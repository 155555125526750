"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var json_ts_1 = require("@daniel-faber/json-ts");
var enums_1 = require("../enums");
var calendarentry_dto_1 = require("./calendarentry.dto");
var json_1 = require("../utils/json");
var UpsertCalendarEntryDto = /** @class */ (function () {
    function UpsertCalendarEntryDto(externalId, referentExternalId, status, businessDivision, bookingNumber, title, location, locationId, customer, customerNumber, contactPerson, region, allowAutoDateRequest, blockingCreationDate, parts) {
        this.externalId = externalId;
        this.referentExternalId = referentExternalId;
        this.status = status;
        this.businessDivision = businessDivision;
        this.bookingNumber = bookingNumber;
        this.title = title;
        this.location = location;
        this.locationId = locationId;
        this.customer = customer;
        this.customerNumber = customerNumber;
        this.contactPerson = contactPerson;
        this.region = region;
        this.allowAutoDateRequest = allowAutoDateRequest;
        this.blockingCreationDate = blockingCreationDate;
        this.parts = parts;
    }
    UpsertCalendarEntryDto.fromJSON = json_ts_1.strictObjectMapper(function (accessor) {
        return new UpsertCalendarEntryDto(accessor.getOptional('externalId', json_ts_1.expectString), accessor.get('referentExternalId', json_ts_1.expectNumber), accessor.get('status', enums_1.calendarEntryStatusFromJSON), accessor.getOptional('businessDivision', enums_1.businessDivisionFromJSON), accessor.getOptional('bookingNumber', json_ts_1.expectString), accessor.get('title', json_ts_1.expectString), accessor.getOptional('location', json_ts_1.expectString), accessor.getOptional('locationId', json_ts_1.expectString), accessor.getOptional('customer', json_ts_1.expectString), accessor.getOptional('customerNumber', json_ts_1.expectString), accessor.getOptional('contactPerson', json_ts_1.expectString), accessor.getOptional('region', json_ts_1.expectString), accessor.getOptional('allowAutoDateRequest', json_ts_1.expectBoolean), accessor.getOptional('blockingCreationDate', json_1.mapToInstant), accessor.get('parts', json_ts_1.arrayMapper(calendarentry_dto_1.CalendarEntryPartDto.fromJSON)));
    });
    return UpsertCalendarEntryDto;
}());
exports.UpsertCalendarEntryDto = UpsertCalendarEntryDto;

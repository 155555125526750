import { Instant, ZonedDateTime, LocalDateTime, ZoneId, LocalDate, LocalTime } from '@js-joda/core';
import {
  CalendarQueryTimeFrame,
} from 'src/calendarComponentLib/loader';
import { Calendar } from '@fullcalendar/core';


const useQueryIntervalFromDate = (fcApi: Calendar | undefined, date: string | null): CalendarQueryTimeFrame => {
  if (fcApi) {
    return {
      activeStart: fcApi.view.activeStart,
      activeEnd: fcApi.view.activeEnd,
    };
  }

  let localDate: LocalDate;
  if (date) {
    localDate = LocalDate.parse(date);
  } else {
    localDate = LocalDate.ofInstant(Instant.ofEpochMilli(new Date().getTime()));
  }

  const initialTimeframeStart: ZonedDateTime = LocalDateTime
    .of(localDate, LocalTime.of(0, 0))
    .atZone(ZoneId.UTC)
    .withDayOfMonth(1)
    .minusDays(7); // add a couple of days before, because the calendar may display up to 6 days from the previous month
  const initialTimeframeEnd: ZonedDateTime = LocalDateTime
    .of(localDate, LocalTime.of(0, 0))
    .atZone(ZoneId.UTC)
    .plusMonths(1)
    .withDayOfMonth(1)
    .plusDays(7); // add a couple of days after, because the calendar may display up to 6 days from the next month
  const activeStart = new Date(initialTimeframeStart.toEpochSecond() * 1000);
  const activeEnd = new Date(initialTimeframeEnd.toEpochSecond() * 1000);

  return {
    activeStart,
    activeEnd,
  };

}

export default useQueryIntervalFromDate;

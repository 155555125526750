import React from 'react';
import ReferentResourceGrid from './ReferentResourceGrid';
import ReferentSearchForm from './search-form/ReferentSearchForm';
import { ReferentDto } from '@hg-aka-prml/tapas-common';
import withAuth, { WithAuthProps } from 'src/containers/withAuth';
import { useLocation, useHistory } from 'react-router-dom';
import { useAsync } from 'react-async';
import { loadReferents } from 'src/api/queries/referent';


function ReferentPage(props: WithAuthProps): JSX.Element {
  const history = useHistory();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const referentIds = search.get('referents');
  const referents: string[] = referentIds ? referentIds.split(',') : [];

  const { authStatus } = props;

  const { data, error } = useAsync<ReferentDto[]>({
    promiseFn: loadReferents,
    watch: authStatus,
    authStatus,
  });

  const allReferents = data || [];

  if (error) {
    throw new Error(`Error loading Referents: ${error}`);
  }

  if (authStatus === 'notAuthenticated') {
    return <div>Please login</div>;
  }

  return (
    <>
      <ReferentSearchForm
        selectedReferentIds={referents}
        allReferents={allReferents}
        onFormChange={(form): void => {
          const referents = form.referents;
          search.set('referents', referents.join(','));
          history.replace({ search: search.toString() })
        }}
      />
      <ReferentResourceGrid
        selectedReferentIds={referents}
        allReferents={allReferents}
      />
    </>
  );
}

export default withAuth(ReferentPage);

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var json_ts_1 = require("@daniel-faber/json-ts");
var ReferentDto = /** @class */ (function () {
    function ReferentDto(externalId, salutation, title, firstname, lastname, email, zipcode, city, status) {
        this.externalId = externalId;
        this.salutation = salutation;
        this.title = title;
        this.firstname = firstname;
        this.lastname = lastname;
        this.email = email;
        this.zipcode = zipcode;
        this.city = city;
        this.status = status;
    }
    ReferentDto.fromJSON = json_ts_1.strictObjectMapper(function (accessor) {
        return new ReferentDto(accessor.get('externalId', json_ts_1.expectInteger), accessor.get('salutation', json_ts_1.expectString), accessor.getOptional('title', json_ts_1.expectString), accessor.get('firstname', json_ts_1.expectString), accessor.get('lastname', json_ts_1.expectString), accessor.get('email', json_ts_1.expectString), accessor.get('zipcode', json_ts_1.expectString), accessor.get('city', json_ts_1.expectString), accessor.get('status', json_ts_1.expectString));
    });
    return ReferentDto;
}());
exports.ReferentDto = ReferentDto;

import React from 'react';
import {
  makeStyles,
  Theme,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles';
import { ToastContainer } from 'react-toastify';
import Container from '@material-ui/core/Container';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import ReferentPage from './ReferentPage';
import { StylesProvider } from '@material-ui/core/styles';
import { ErrorBoundary } from './ErrorBoundary';

const useStyles = makeStyles((theme: Theme) => ({
  containerStyles: {
    padding: theme.spacing(3),
  },
}));

const theme = createMuiTheme({
  typography: {
    h3: {
      // See also: styles.css
      fontSize: '1.25rem',
      fontWeight: 400,
      letterSpacing: '0.8px',
    },
    fontFamily: [
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif',
    ].join(','),
  },
});

const App: React.FC = () => {
  const classes = useStyles();
  return (
    <ErrorBoundary>
      {/* https://material-ui.com/styles/advanced/#injectfirst */}
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Router>
            <main
              style={{ minHeight: 'calc(100vh - 117px) /*footer height*/' }}
            >
              <Header />
              <Container className={classes.containerStyles} maxWidth={false}>
                <Switch>
                  <Route path="/">
                    <ReferentPage />
                  </Route>
                </Switch>
              </Container>
            </main>
            <Footer />
            <ToastContainer />
          </Router>
        </ThemeProvider>
      </StylesProvider>
    </ErrorBoundary>
  );
};

export default App;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var json_ts_1 = require("@daniel-faber/json-ts");
var json_1 = require("../utils/json");
var UpsertReferentDto = /** @class */ (function () {
    function UpsertReferentDto(salutation, title, firstname, lastname, email, timestamp, zipcode, city, status) {
        this.salutation = salutation;
        this.title = title;
        this.firstname = firstname;
        this.lastname = lastname;
        this.email = email;
        this.timestamp = timestamp;
        this.zipcode = zipcode;
        this.city = city;
        this.status = status;
    }
    UpsertReferentDto.fromJSON = json_ts_1.strictObjectMapper(function (accessor) {
        return new UpsertReferentDto(accessor.get('salutation', json_ts_1.expectString), accessor.getOptional('title', json_ts_1.expectString), accessor.get('firstname', json_ts_1.expectString), accessor.get('lastname', json_ts_1.expectString), accessor.get('email', json_ts_1.expectString), accessor.get('timestamp', json_1.mapToInstant), accessor.get('zipcode', json_ts_1.expectString), accessor.get('city', json_ts_1.expectString), accessor.get('status', json_ts_1.expectString));
    });
    return UpsertReferentDto;
}());
exports.UpsertReferentDto = UpsertReferentDto;

import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Calendar } from '@fullcalendar/core/Calendar';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { LocalDate, LocalDateTime, LocalTime, ZoneOffset } from '@js-joda/core';
import DatePicker from 'src/components/datetime/DatePicker';
import { Popover, Theme, withStyles, StyledComponentProps } from '@material-ui/core';

function assumeType<T>(x: unknown): asserts x is T {
  return;
}

interface LegendProps extends RouteComponentProps , StyledComponentProps {
  api?: Calendar;
};

type LegendState = {
  anchorEl: HTMLElement | null;
  modalOpen: boolean;
};


type StyleProps = {};

const styles = (theme: Theme): StyleProps => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(3),
    borderRadius: 0,
    lineHeight: 1.3,
  },
});


class HeaderButtonCalendar extends Component<LegendProps, LegendState> {
  
  state = {
    modalOpen: false,
    anchorEl: null,
  };

  handlePopoverOpen = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  
  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  handleCalendarIconClick = (event: React.SyntheticEvent) => {
    this.setState({
      modalOpen: true
    });
  }

  handleOnClose = () => {
    this.setState({
      modalOpen: false
    });
  }

  handleDatePickerChange = (date: MaterialUiPickersDate) => {
    // Work around TS compiler errors
    assumeType<LocalDate>(date);

    const search = new URLSearchParams(this.props.location.search);
    const dateParam = date.toString();
    search.set('date', dateParam);
    this.props.history.push({ search: search.toString() })
    if (this.props.api) {
      const localDateTime = LocalDateTime.of(date, LocalTime.of(0, 0))
      this.props.api.gotoDate(new Date(localDateTime.toEpochSecond(ZoneOffset.UTC) * 1000))
    }
  }

  render() {
    const open = Boolean(this.state.anchorEl);
    const { classes } = this.props;
    const queryParams = new URLSearchParams(this.props.location.search);
    const date = queryParams.get('date');
    let defaultDate: LocalDate;
    if (date) {
      defaultDate = LocalDate.parse(date);
    } else {
      defaultDate = LocalDate.now();
    }
    
    
    return (
      <>
        <button
          onClick={this.handleCalendarIconClick}
          type="button"
          className="fc-button fc-button-primary"
          onMouseEnter={this.handlePopoverOpen} 
          onMouseLeave={this.handlePopoverClose}
        >
          {/* https://feathericons.com/?query=cal */}
          <svg style={{ width: '18px', paddingTop: '2px' }} width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line>
            <line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line>
          </svg>
        </button>
        <Popover 
          className= {classes && classes.popover}
          classes={{ paper: classes && classes.paper }}
          open={open}
          anchorEl={this.state.anchorEl}
          onClose={this.handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          disableRestoreFocus
        >
          Zeitraum wählen
        </Popover>
        <DatePicker
          style={{ display: 'none' }}
          onClose={this.handleOnClose}
          open={this.state.modalOpen}
          onChange={this.handleDatePickerChange}
          value={defaultDate}
        />
      </>
    );
  }
}

export default withStyles(styles)(withRouter(HeaderButtonCalendar));

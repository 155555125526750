import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    fontSize: '16px',
    height: '77px',
    backgroundColor: '#ababab',
    textAlign: 'center',
    padding: '0',
    overflow: 'auto',
  },
});

export default function Footer(): JSX.Element {
  const classes = useStyles();
  return (
    <footer className={classes.root}>
      <span dangerouslySetInnerHTML={{ __html: `<!-- ${JSON.stringify(process.env)} -->` }} />
    </footer>
  );
}

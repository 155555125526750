import React from 'react';
import {
  DatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import createJsJodaUtils from '@prinzdezibel/date-io-js-joda';
import { Locale as JsJodaLocale } from "@js-joda/locale_de-de";
import { LocalDate } from '@js-joda/core';
import { DateType } from '@date-io/type';


const utils = createJsJodaUtils(LocalDate);

type Props = {
  style?: React.CSSProperties;
  open?: boolean;
  error?: boolean;
  helperText?: string;
  placeholder?: string;
  value?: LocalDate | null;
  onError?: (error: React.ReactNode, date: DateType<LocalDate>) => void;
  onChange: (date: DateType<LocalDate> | null, value?: string | null) => void;
  onClose?: () => void;
}

export default function LocalizedDatePicker({ value, onChange, placeholder, error, helperText, onError, open, onClose, style }: Props): JSX.Element {
  return (
    <MuiPickersUtilsProvider utils={utils} locale={JsJodaLocale.GERMANY}>
      <DatePicker
        open={open}
        onClose={onClose}
        format="dd.MM.yyyy"
        onChange={onChange}
        placeholder={placeholder}
        style={style}
        onError={onError}
        value={value}
        {...(error ? { error } : {})}
        {...(helperText ? { helperText } : {})}
      />
    </MuiPickersUtilsProvider>
  );
}

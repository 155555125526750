import React, { ErrorInfo } from 'react';
import { initAwsClient } from 'src/api/aws-client';
import { ExpiredTokenException } from 'src/types/custom-error-types';
import { sendErrorLogMessage } from '../api/serverLogger';
import { oauth, STOWAWAY } from '../authenticationInformation';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';


export class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
  public constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  public async componentDidCatch(error: Error, info: ErrorInfo): Promise<void> {
    if (error instanceof ExpiredTokenException) {
      // Session has expired. Redirect to login page.
      await initAwsClient();
      Auth.configure({ oauth });
      Auth.federatedSignIn(
        {
          provider: CognitoHostedUIIdentityProvider.Cognito,
          customState: btoa(STOWAWAY + window.location.search)
        }
      );
    } else {
      this.setState({ hasError: true });
      sendErrorLogMessage(`${error}`, `ErrorBoundary info=${JSON.stringify(info)}`);
    }
  }

  public render(): React.ReactNode {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}

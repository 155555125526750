import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles, Theme } from '@material-ui/core/styles';
// import { deepOrange, deepPurple, cyan } from '@material-ui/core/colors';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#000',
    fontSize: '.8rem',
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginLeft: '5px',
    fontWeight: 500,
  },
}));

type Props = {
  initials: string;
};

export default function LetterAvatars({ initials }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Avatar sizes="small" className={classes.root}>
      {initials}
    </Avatar>
  );
}

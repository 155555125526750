"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var json_ts_1 = require("@daniel-faber/json-ts");
var BusinessDivision;
(function (BusinessDivision) {
    BusinessDivision["KFF"] = "KFF";
    BusinessDivision["Inhouse"] = "Inhouse";
    BusinessDivision["MTS"] = "MTS";
    BusinessDivision["Inhouse_Training"] = "Inhouse Training";
    BusinessDivision["Inhouse_Consulting"] = "Inhouse Consulting";
    BusinessDivision["UNKNOWN"] = "UNKNOWN";
})(BusinessDivision = exports.BusinessDivision || (exports.BusinessDivision = {}));
exports.businessDivisionFromJSON = json_ts_1.enumMapperByValue(BusinessDivision);
var CalendarEntryStatus;
(function (CalendarEntryStatus) {
    CalendarEntryStatus["Requested"] = "Requested";
    CalendarEntryStatus["Blocked"] = "Blocked";
})(CalendarEntryStatus = exports.CalendarEntryStatus || (exports.CalendarEntryStatus = {}));
exports.calendarEntryStatusFromJSON = json_ts_1.enumMapperByValue(CalendarEntryStatus);
var InquiryAnswerStatus;
(function (InquiryAnswerStatus) {
    InquiryAnswerStatus["Accepted"] = "Accepted";
    InquiryAnswerStatus["Rejected"] = "Rejected";
})(InquiryAnswerStatus = exports.InquiryAnswerStatus || (exports.InquiryAnswerStatus = {}));
exports.inquiryAnswerStatusFromJSON = json_ts_1.enumMapperByValue(InquiryAnswerStatus);
var CombinedStatus;
(function (CombinedStatus) {
    CombinedStatus["Requested"] = "Requested";
    CombinedStatus["Blocked"] = "Blocked";
    CombinedStatus["Accepted"] = "Accepted";
    CombinedStatus["Rejected"] = "Rejected";
    CombinedStatus["Manuell"] = "Manuell";
})(CombinedStatus = exports.CombinedStatus || (exports.CombinedStatus = {}));
exports.combinedStatusFromJSON = json_ts_1.enumMapperByValue(CombinedStatus);

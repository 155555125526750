import React from 'react';
import clsx, { ClassDictionary } from 'clsx';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import { ReferentDto } from '@hg-aka-prml/tapas-common';
import InitialsAvatar from './InitialsAvatar';
import ReferentPickerInput from './ReferentPickerInput';

type Props = {
  selectedReferents: ReferentDto[];
  classes?: ClassDictionary;
  className?: string;
  id: string;
  onChange?: (event: React.ChangeEvent<unknown>, value: ReferentDto[]) => void;
  referents?: ReferentDto[];
};

export default function ReferentPicker({
  selectedReferents,
  onChange,
  referents,
  id,
  classes,
  className,
}: Props): JSX.Element {
  const selectedIds = selectedReferents.map(ref => ref.externalId);
  referents = referents?.filter(
    ref => selectedIds.indexOf(ref.externalId) === -1,
  );
  return (
    <Autocomplete
      className={clsx(classes && classes.root, className)}
      onChange={onChange}
      multiple
      filterSelectedOptions
      disableClearable
      id={id}
      value={selectedReferents}
      PaperComponent={(props): JSX.Element => <Paper square {...props} />}
      options={referents}
      renderTags={(value: ReferentDto[], getTagProps): JSX.Element[] =>
        value.map((referent: ReferentDto, index: number) => {
          let label = `${referent.firstname} ${referent.lastname}`;
          if (
            (referent.zipcode || referent.city) &&
            referent.zipcode !== '00000'
          ) {
            label += ` (${referent.zipcode} ${referent.city})`;
          }
          return (
            <Chip
              style={{ margin: '5px' }}
              key={index}
              size="medium"
              variant="outlined"
              color="secondary"
              avatar={
                <InitialsAvatar
                  initials={`${referent.firstname[0].toUpperCase()}${referent.lastname[0].toUpperCase()}`}
                />
              }
              label={label}
              {...getTagProps({ index })}
            />
          );
        })
      }
      getOptionLabel={(referent: ReferentDto): string =>
        `${referent.firstname} ${referent.lastname}`
      }
      renderInput={(params): JSX.Element => {
        return <ReferentPickerInput {...params} fullWidth />;
      }}
    />
  );
}

import { DateTimeFormatter, Instant, LocalDateTime, ZonedDateTime, ZoneId } from '@js-joda/core';

import '@js-joda/timezone';

const formatter = DateTimeFormatter.ofPattern('dd.MM.yyyy HH:mm');

const zoneId = ZoneId.of('Europe/Berlin');

export function formatDateTime(dateTime: Instant | LocalDateTime | ZonedDateTime): string {
  if (dateTime instanceof Instant) {
    dateTime = LocalDateTime.ofInstant(dateTime, zoneId);
  }
  return formatter.format(dateTime);
}

import React from 'react';
import { Auth, Hub } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { initAwsClient, deleteAwsClient } from 'src/api/aws-client';
import { withRouter } from 'react-router-dom';
import { STOWAWAY } from '../authenticationInformation'


export type WithAuthProps = {
  authStatus: 'notAuthenticated' | 'signedIn';
  authData: string | null;
  authError: string | null;
  signIn: (event: React.SyntheticEvent) => void;
  signOut: () => void;
  state?: Readonly<any>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function WithAuthWrapper(Component: any): any {
  class WithAuth extends React.Component<any> {
    _isMounted = false;
    state: {
      authStatus: WithAuthProps['authStatus'];
      authData: WithAuthProps['authData'];
      authError: WithAuthProps['authError'];
    } = {
        authStatus: 'notAuthenticated',
        authData: null,
        authError: null,
      };

    componentDidMount(): void {
      this._isMounted = true;
      Hub.listen('auth', this.listener);
      this.checkAuthorization();
    }

    componentWillUnmount(): void {
      this._isMounted = false;
      Hub.remove('auth', this.listener);
    }

    checkAuthorization(): void {
      Auth.currentAuthenticatedUser()
        .then(user => {
          this._isMounted &&
            this.setState({
              authStatus: 'signedIn' as const,
              authData: user,
              authError: null,
            });
        })
        .catch(e => {
          if (e === 'not authenticated') {
            this._isMounted &&
              this.setState({
                authStatus: 'notAuthenticated' as const,
                authData: null,
                authError: null,
              });
          } else {
            this._isMounted &&
              this.setState({
                authStatus: 'notAuthenticated' as const,
                authData: null,
                authError: e,
              });
          }
        });
    }

    listener = async (
      data: import('@aws-amplify/core/lib-esm/Hub').HubCapsule,
    ): Promise<void> => {
      switch (data.payload.event) {
        case 'cognitoHostedUI':
          // A user has been signed in via Cognito Hosted UI
          break;
        case 'signIn':
          // A user has been signed in (Cognito Hosted UI or by any other means)
          // Init/Re-Init aws client
          await initAwsClient();
          this.setState({
            authStatus: 'signedIn' as const,
            authData: data.payload.data,
            authError: null,
          });
          break;
        case 'signIn_failure':
          await deleteAwsClient();
          this.setState({
            authStatus: 'notAuthenticated' as const,
            authData: null,
            authError: data.payload.data,
          });
          break;
        case 'oAuthSignOut':
        case 'signOut':
          await deleteAwsClient();
          this.setState({
            authStatus: 'notAuthenticated' as const,
            authData: null,
            authError: null,
          });
          break;
        case 'customOAuthState':
          // If custom state was passed in aws-amplify will call this hook.

          // HACK: We pass in redirectSignIn as undefined to suppress document reload
          // See: https://github.com/aws-amplify/amplify-js/blob/c27b8164995220090967f782ccd8e42ad4fa4bdb/packages/auth/src/Auth.ts#L1934
          const oauth = {
            redirectSignIn: undefined,
          };
          // Repeat initialisation of Auth object to set redirectSignIn to undefined
          Auth.configure({ oauth });

          // Instead we do client side routing via react-router (which hopefully doesn't rerender the whole document)
          let queryParams = '';
          if (data.payload?.data) {
            queryParams = atob(decodeURIComponent(data.payload.data));
            // Remove the stowaway from payload again
            queryParams = queryParams.substr(STOWAWAY.length);
            this.props.history.push({ search: queryParams })
          }
          break;
        case 'configured':
        case 'signUp':
        case 'default':
          break;
      }
    };

    signOut = (): void => {
      Auth.signOut()
        .then(() => {
          this.setState({
            authStatus: 'notAuthenticated' as const,
            authData: null,
            authError: null,
          });
        })
        .catch(e => {
          this.setState({
            authData: null,
            authError: e.message,
          });
          console.error(e);
        });
    };

    signIn = (ev: React.SyntheticEvent<Element, Event>): void => {
      const b64 = btoa(STOWAWAY + window.location.search);
      Auth.federatedSignIn(
        {
          provider: CognitoHostedUIIdentityProvider.Cognito,
          customState: b64
        }
      );
    }

    render(): JSX.Element {
      return (
        <Component
          signIn={this.signIn}
          signOut={this.signOut}
          {...this.state}
          {...this.props}
        />
      );
    }
  }
  return withRouter(WithAuth);
}

export default WithAuthWrapper;

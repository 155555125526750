import { Year, LocalDate, Month } from '@js-joda/core';

export class Holiday {

  public constructor(
    public readonly date: LocalDate,
    public readonly name: string,
    public readonly state: GermanState[] = [],
  ) {
  }

}

export class Holidays {

  public constructor(
    private readonly holidays: Array<Holiday>,
  ) {
  }

  public getAt(date: LocalDate): Array<Holiday> {
    return this.holidays.filter(h => h.date.isEqual(date));
  }

  public getAll(): Array<Holiday> {
    return [...this.holidays];
  }

}

export enum GermanState {
  BadenWürttemberg = 'Baden-Württemberg',
  Bayern = 'Bayern',
  Berlin = 'Berlin',
  Brandenburg = 'Brandenburg',
  Bremen = 'Bremen',
  Hamburg = 'Hamburg',
  Hessen = 'Hessen',
  MecklenburgVorpommern = 'Mecklenburg-Vorpommern',
  Niedersachsen = 'Niedersachsen',
  NordrheinWestfalen = 'Nordrhein-Westfalen',
  RheinlandPfalz = 'Rheinland-Pfalz',
  Saarland = 'Saarland',
  Sachsen = 'Sachsen',
  SachsenAnhalt = 'Sachsen-Anhalt',
  SchleswigHolstein = 'Schleswig-Holstein',
  Thüringen = 'Thüringen',
}

/**
 * Calculates the German holidays as described at https://de.wikipedia.org/wiki/Gesetzliche_Feiertage_in_Deutschland.
 */
export function calculateGermanHolidays(year: Year): Holidays {

  const holidays: Array<Holiday> = [];

  function addHoliday(date: LocalDate, name: string, states?: GermanState[]): void;
  function addHoliday(month: number | Month, day: number, name: string, states?: GermanState[]): void;
  function addHoliday(arg1: LocalDate | number | Month, arg2: string | number, arg3?: string | GermanState[], states?: GermanState[]): void {
    if (arg1 instanceof LocalDate) {
      holidays.push(new Holiday(arg1, arg2.toString(), arg3 as GermanState[] | undefined));
    } else {
      holidays.push(new Holiday(LocalDate.of(year.value(), arg1, arg2 as number), arg3 as string, states));
    }
  }

  const easter = easterDate(year);

  addHoliday(Month.JANUARY, 1, 'Neujahr');

  addHoliday(Month.JANUARY, 6, 'Heilige Drei Könige',
    [GermanState.BadenWürttemberg, GermanState.Bayern, GermanState.SachsenAnhalt]
  );

  addHoliday(Month.MARCH, 8, 'Frauentag', [GermanState.Berlin]);

  addHoliday(easter.minusDays(2), 'Karfreitag');
  addHoliday(easter, 'Ostersonntag', [GermanState.Brandenburg, GermanState.Hessen]);
  addHoliday(easter.plusDays(1), 'Ostermontag');

  addHoliday(Month.MAY, 1, 'Tag der Arbeit');

  addHoliday(easter.plusDays(39), 'Christi Himmelfahrt');
  addHoliday(easter.plusDays(49), 'Pfingstsonntag', [GermanState.Brandenburg, GermanState.Hessen]);
  addHoliday(easter.plusDays(50), 'Pfingstmontag');
  addHoliday(easter.plusDays(60), 'Fronleichnam', [GermanState.BadenWürttemberg, GermanState.Bayern, GermanState.Hessen, GermanState.Saarland,
  GermanState.Sachsen, GermanState.Thüringen, GermanState.NordrheinWestfalen, GermanState.RheinlandPfalz]);

  addHoliday(Month.AUGUST, 8, 'Augsburger Hohes Friedensfest (nur Stadt Augsburg)', [GermanState.Bayern]);
  addHoliday(Month.AUGUST, 15, 'Mariä Himmelfahrt (In Bayern nicht überall)', [GermanState.Saarland, GermanState.Bayern]);
  addHoliday(Month.SEPTEMBER, 20, 'Weltkindertag', [GermanState.Thüringen]);
  addHoliday(Month.OCTOBER, 3, 'Tag der Deutschen Einheit');

  if (
    year.value() === 2017
  ) {
    addHoliday(Month.OCTOBER, 31, 'Reformationstag', [
      GermanState.Brandenburg,
      GermanState.Bremen,
      GermanState.Hamburg,
      GermanState.MecklenburgVorpommern,
      GermanState.Niedersachsen,
      GermanState.Sachsen,
      GermanState.SachsenAnhalt,
      GermanState.SchleswigHolstein,
      GermanState.Thüringen
    ]);
  }

  addHoliday(Month.NOVEMBER, 1, 'Allerheiligen', [
    GermanState.BadenWürttemberg,
    GermanState.Bayern,
    GermanState.NordrheinWestfalen,
    GermanState.RheinlandPfalz,
    GermanState.Saarland
  ]);

  const nov23 = LocalDate.of(year.value(), Month.NOVEMBER, 23);
  const daysToSubtract = (nov23.dayOfWeek().value() + 3) % 7 + 1;
  addHoliday(nov23.minusDays(daysToSubtract), 'Buß- und Bettag', [GermanState.Sachsen]);

  addHoliday(Month.DECEMBER, 25, '1. Weihnachtstag');
  addHoliday(Month.DECEMBER, 26, '2. Weihnachtstag');

  return new Holidays(holidays);
}

// see https://de.wikipedia.org/wiki/Gau%C3%9Fsche_Osterformel#Eine_erg%C3%A4nzte_Osterformel
export function easterDate(year: Year): LocalDate {
  const x = year.value();
  const k = Math.floor(x / 100);
  const m = 15 + Math.floor((3 * k + 3) / 4) - Math.floor((8 * k + 13) / 25);
  const s = 2 - Math.floor((3 * k + 3) / 4);
  const a = x % 19;
  const d = (19 * a + m) % 30;
  const r = Math.floor((d + Math.floor(a / 11)) / 29);
  const og = 21 + d - r;
  const sz = 7 - (x + Math.floor(x / 4) + s) % 7;
  const oe = 7 - (og - sz) % 7;
  const os = og + oe;
  return LocalDate.of(x, Month.MARCH, 1).plusDays(os - 1);
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var json_ts_1 = require("@daniel-faber/json-ts");
var core_1 = require("@js-joda/core");
function mapToInstant(jsonValue) {
    var s = json_ts_1.expectString(jsonValue);
    try {
        return core_1.Instant.parse(s);
    }
    catch (e) {
        throw new json_ts_1.JsonMappingError("failed to parse string as instant: " + e);
    }
}
exports.mapToInstant = mapToInstant;
function mapToLocalDate(jsonValue) {
    var s = json_ts_1.expectString(jsonValue);
    try {
        return core_1.LocalDate.parse(s);
    }
    catch (e) {
        throw new json_ts_1.JsonMappingError("failed to parse string as local date: " + e);
    }
}
exports.mapToLocalDate = mapToLocalDate;

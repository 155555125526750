import { makeStyles, Theme } from '@material-ui/core';

export type StyleProps = {
  timeGridWidth: string;
};

export const eventColorRequested = '#bbbbbbaa';
export const eventColorBlocked = '#626262aa';
export const eventColorAcceptedValid = '#626262aa';
export const eventColorRejectedValid = '#ff0000aa';
export const eventColorRequestedAgain = '#0000FF';
export const eventColorManuell = '#800080';

export const useStyles = makeStyles<Theme, StyleProps>(() => ({
  timeGridStyles: (props): {} => ({
    '& .fc-view': {
      overflowX: 'auto',
      overflowY: 'hidden',
      backgroundColor: '#fff',
    },
    '& .fc-view > table': {
      width: `${props.timeGridWidth}`,
      minWidth: '100%',
    },
    '& .fc-slats': {
      zIndex: 4,
      pointerEvents: 'none',
    },
    '& .fc-time-grid .fc-slats .fc-minor td': {
      border: 'none',
    },
    '& .fc-widget-header thead tr:nth-of-type(2)': {
      fontSize: 0,
    },
    '& .fc-event': {
      borderRadius: '9px',
      boxShadow: '9px 10px 5px -5px rgba(0,0,0,0.17)',
    },
    '& .fc-event-Requested': {
      backgroundColor: eventColorRequested,
      borderColor: eventColorRequested,
    },
    '& .fc-event-Blocked': {
      backgroundColor: eventColorBlocked,
      borderColor: eventColorBlocked,
    },
    '& .fc-event-Manuell': {
      backgroundColor: eventColorManuell,
      borderColor: eventColorManuell,
    },
    '& .fc-event-inquiryAnswer-valid.fc-event-inquiryAnswer-Accepted': {
      backgroundColor: eventColorAcceptedValid,
      borderColor: eventColorAcceptedValid,
    },
    '& .fc-event-inquiryAnswer-valid.fc-event-inquiryAnswer-Rejected': {
      backgroundColor: eventColorRejectedValid,
      borderColor: eventColorRejectedValid,
    },
    '& .fc-scroller.fc-time-grid-container': {
      overflow: 'initial !important',
    },
    '& .fc-axis': {
      position: 'sticky',
      left: 0,
      background: 'white',
    },
    '& .fc-sun, .fc-sat, .holiday': {
      backgroundColor: '#eeeeff',
    },
    '& a[data-goto]:hover': {
      textDecoration: 'none',
    },
    '& .fc-day-header a[data-goto]': {
      cursor: 'default',
    },
    '& .fc-day-header a[data-goto] .holiday': {
      cursor: 'pointer',
    },
    '& .fc-day, & .fc-day.fc-today': {
      borderLeft: 'none',
      borderRight: 'none',
    },
    '& .fc-day.sep': {
      borderLeft: '2px solid rgb(180,180,180)',
    },
    '& .fc-body > tr > td.fc-widget-content': {
      borderRight: '1px solid #ddd',
    },
    '& .fc-axis.fc-widget-content': {
      borderRight: '3px solid #ddd',
    },
  }),
}));

export const usePopoverStyles = makeStyles(() => ({
  popoverContent: {
    fontWeight: 400,
    fontSize: '12px',
    padding: '12px',
  },
}));

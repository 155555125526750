import React from 'react';
import {
  BusinessDivision,
  CombinedStatus,
  InquiryAnswerDto,
} from '@hg-aka-prml/tapas-common';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import { Modal } from '@material-ui/core';

import { formatDateTime } from '../formatDateTime';
import styles from './CalendarEventBlock.module.css';
import { Instant } from "@js-joda/core";


export type EventInfo = {
  initials: string;
  eventId: string;
  referentName: string | undefined;
  startTime: string | null;
  endTime: string | null;
  bookingNumber: string | undefined;
  businessDivision: BusinessDivision | undefined;
  contactPerson: string | undefined;
  location: string | undefined;
  locationId: string | undefined;
  title: string;
  customer: string | undefined;
  customerNumber: string | undefined;
  region: string | undefined;
  status: CombinedStatus;
  date: string | undefined;
  inquiryAnswer: InquiryAnswerDto | undefined;
  blockingCreationDate: Instant | undefined | null;
};

type EventInfoProps = {
  info: EventInfo | undefined | null;
  activeView: string;
};

function getModalStyle() {
  return {
    top: '50%',
    left: '50%',
    background: 'white',
    width: '30%',
    height: '30%',
    transform: 'translate(-60%, -60%)',
    position: 'absolute' as const,
  };
}

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(3),
    borderRadius: 0,
    lineHeight: 1.3,
  },
}));

function CalendarEventBlock(props: EventInfoProps): JSX.Element {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [modalOpen, setModalOpen] = React.useState(false);
  const handlePopoverOpen = (event: any): void => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = (): void => {
    setAnchorEl(null);
  };
  const handleClick = (): void => {
    console.log('click');
    handleModalOpen();
  };
  const handleModalOpen = (): void => {
    setModalOpen(true);
  };

  const handleModalClose = (): void => {
    setModalOpen(false);
  };

  const infoForDayBlock = (): JSX.Element => {
    return (
      <div
        style={{ lineHeight: '1.3em', fontSize: '1.1em', marginLeft: '1.2em' }}
      >
        {props.info?.date}
        <br />
        {props.info?.startTime} - {props.info?.endTime} Uhr
        <br />
        {props.info?.referentName}
        <br />
        Veranstaltungsort: {props.info?.location}
      </div>
    );
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <div
        style={{
          lineHeight: '2',
          marginLeft: '1px',
          fontWeight: 500,
          color: 'white',
          left: '0%',
          right: '0%',
          top: '0%',
          bottom: '0%',
          position: 'absolute',
        }}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={handleClick}
      >
        {props.info?.initials}
        {props.activeView === 'resourceTimeGridDay' && infoForDayBlock()}
      </div>
      <Popover
        className={classes.popover}
        classes={{ paper: classes.paper }}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        disableRestoreFocus
      >
        <Content info={props.info} activeView={props.activeView} />
      </Popover>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={modalOpen}
        onClose={handleModalClose}
      >
        <div style={getModalStyle()} className={classes.paper}>
          <Content info={props.info} activeView={props.activeView} />
        </div>
      </Modal>
    </>
  );
}

const StatusView: React.FC<{ eventInfo: EventInfo }> = ({ eventInfo }) => {
  switch (eventInfo.status) {
    case CombinedStatus.Blocked:
        return <em className={`${styles.entryStatus} ${styles.statusBlocked}`} title={`${eventInfo.referentName} hat dem Termin bereits zugestimmt, bevor er im System angelegt wurde.`}>Geblockt</em>;
    case CombinedStatus.Requested:
        return <em className={`${styles.entryStatus} ${styles.statusRequested}`} title={`${eventInfo.referentName} muss die Terminanfrage in der Arbeitsumgebung beantworten.`}>Angefragt</em>;
    case CombinedStatus.Manuell:
        return <em className={`${styles.entryStatus} ${styles.statusManuell}`} title={`Anfrage mit ${eventInfo.referentName} lief manuell ab`}>Manuell</em>;
    case CombinedStatus.Accepted:
        return eventInfo.inquiryAnswer!.valid
            ? <em className={`${styles.entryStatus} ${styles.statusAccepted}`} title={`${eventInfo.referentName} hat den Termin am ${formatDateTime(eventInfo.inquiryAnswer!.lastModified)} bestätigt`}>Bestätigt</em>
            : <em className={`${styles.entryStatus} ${styles.statusRequested}`} title={`${eventInfo.referentName} hat den Termin am ${formatDateTime(eventInfo.inquiryAnswer!.lastModified)} bestätigt, aber der Termin wurde danach geändert!`}>Angefragt (!)</em>;
    case CombinedStatus.Rejected:
        return eventInfo.inquiryAnswer!.valid
            ? <em className={`${styles.entryStatus} ${styles.statusRejected}`} title={`${eventInfo.referentName} hat den Termin am ${formatDateTime(eventInfo.inquiryAnswer!.lastModified)} abgelehnt`}>Abgelehnt</em>
            : <em className={`${styles.entryStatus} ${styles.statusRequested}`} title={`${eventInfo.referentName} hat den Termin am ${formatDateTime(eventInfo.inquiryAnswer!.lastModified)} abgelehnt, aber der Termin wurde danach geändert!`}>Angefragt (!)</em>;
  }
};

function Content(props: EventInfoProps): JSX.Element {
  return (
    <div>
      <strong>
        {props.info && props.info.date}
      </strong>
      <br />
      <strong>
        {props.info && props.info.startTime} -{' '}
        {props.info && props.info.endTime} Uhr
      </strong>
      <br />
      <strong>{props.info && props.info.referentName}</strong> {props?.info && <StatusView eventInfo={props.info} />}
      <br />
      {props.info && props.info.location && (
        <>
          Veranstaltungsort: {props.info.location}
          <br />
        </>
      )}
      {props.info && props.info.businessDivision && (
        <>
          Akademie-Bereich: {props.info.businessDivision}
          <br />
        </>
      )}
      {props.info && props.info.contactPerson && (
        <>
          Interner Ansprechpartner: {props.info.contactPerson}
          <br />
        </>
      )}
      {props.info && props.info.bookingNumber && (
        <>
          Buchungsnummer: {props.info.bookingNumber}
          <br />
        </>
      )}
      {props.info && props.info.title && (
        <>
          Titel: {props.info.title}
          <br />
        </>
      )}
      {props.info && props.info.customer && (
        <>
          Kunde: {props.info.customer}
          <br />
        </>
      )}
      {props.info && props.info.customerNumber && (
        <>
          Kundennummer: {props.info.customerNumber}
          <br />
        </>
      )}
      {props.info && props.info.region && (
        <>
          Region: {props.info.region}
          <br />
        </>
      )}
    </div>
  );
}

export default CalendarEventBlock;

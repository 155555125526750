import { MutableRefObject } from 'react';


function handleScroll(this: HTMLElement, calendarViewScrollLeft: MutableRefObject<number | null>, ev: Event): void {
  calendarViewScrollLeft.current = this.scrollLeft;
}

export function registerForUpdateUponScroll(calendarViewScrollLeft: MutableRefObject<number | null>, el: HTMLElement): void {
  const handler = function (this: HTMLElement, ev: Event): void { handleScroll.call(this, calendarViewScrollLeft, ev); };
  // Unregister event listener for the scrolling event if any.
  el.removeEventListener('scroll', handler);
  el.addEventListener('scroll', handler);
};

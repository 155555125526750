"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var json_ts_1 = require("@daniel-faber/json-ts");
var enums_1 = require("../enums");
var json_1 = require("../utils/json");
var CalendarEntryDto = /** @class */ (function () {
    function CalendarEntryDto(externalId, referentExternalId, status, businessDivision, bookingNumber, title, location, locationId, customer, customerNumber, contactPerson, region, allowAutoDateRequest, parts, fingerprint, inquiryAnswer, blockingCreationDate) {
        this.externalId = externalId;
        this.referentExternalId = referentExternalId;
        this.status = status;
        this.businessDivision = businessDivision;
        this.bookingNumber = bookingNumber;
        this.title = title;
        this.location = location;
        this.locationId = locationId;
        this.customer = customer;
        this.customerNumber = customerNumber;
        this.contactPerson = contactPerson;
        this.region = region;
        this.allowAutoDateRequest = allowAutoDateRequest;
        this.parts = parts;
        this.fingerprint = fingerprint;
        this.inquiryAnswer = inquiryAnswer;
        this.blockingCreationDate = blockingCreationDate;
    }
    CalendarEntryDto.fromJSON = json_ts_1.strictObjectMapper(function (accessor) {
        return new CalendarEntryDto(accessor.get('externalId', json_ts_1.expectString), accessor.get('referentExternalId', json_ts_1.expectNumber), accessor.get('status', enums_1.combinedStatusFromJSON), accessor.getOptional('businessDivision', enums_1.businessDivisionFromJSON), accessor.getOptional('bookingNumber', json_ts_1.expectString), accessor.get('title', json_ts_1.expectString), accessor.getOptional('location', json_ts_1.expectString), accessor.getOptional('locationId', json_ts_1.expectString), accessor.getOptional('customer', json_ts_1.expectString), accessor.getOptional('customerNumber', json_ts_1.expectString), accessor.getOptional('contactPerson', json_ts_1.expectString), accessor.getOptional('region', json_ts_1.expectString), accessor.get('allowAutoDateRequest', json_ts_1.expectBoolean), accessor.get('parts', json_ts_1.arrayMapper(CalendarEntryPartDto.fromJSON)), accessor.get('fingerprint', json_ts_1.expectString), accessor.getOptional('inquiryAnswer', InquiryAnswerDto.fromJSON), accessor.getOptional('blockingCreationDate', json_1.mapToInstant));
    });
    return CalendarEntryDto;
}());
exports.CalendarEntryDto = CalendarEntryDto;
var CalendarEntryPartDto = /** @class */ (function () {
    function CalendarEntryPartDto(startTime, endTime) {
        this.startTime = startTime;
        this.endTime = endTime;
        if (!endTime.isAfter(startTime)) {
            throw new Error('endTime must be after startTime');
        }
    }
    CalendarEntryPartDto.fromJSON = json_ts_1.strictObjectMapper(function (accessor) {
        try {
            var startTime = accessor.get('startTime', json_1.mapToInstant);
            var endTime = accessor.get('endTime', json_1.mapToInstant);
            return new CalendarEntryPartDto(startTime, endTime);
        }
        catch (e) {
            throw new json_ts_1.JsonMappingError(e.message);
        }
    });
    return CalendarEntryPartDto;
}());
exports.CalendarEntryPartDto = CalendarEntryPartDto;
var InquiryAnswerDto = /** @class */ (function () {
    function InquiryAnswerDto(status, lastModified, valid) {
        this.status = status;
        this.lastModified = lastModified;
        this.valid = valid;
    }
    InquiryAnswerDto.fromJSON = json_ts_1.strictObjectMapper(function (accessor) {
        return new InquiryAnswerDto(accessor.get('status', enums_1.inquiryAnswerStatusFromJSON), accessor.get('lastModified', json_1.mapToInstant), accessor.get('valid', json_ts_1.expectBoolean));
    });
    return InquiryAnswerDto;
}());
exports.InquiryAnswerDto = InquiryAnswerDto;

import { DateInput } from '@fullcalendar/core/datelib/env';
import { Holidays } from 'src/holidays';
import { Locale as JsJodaLocale } from '@js-joda/locale_de-de';

import {
  LocalDate,
  DateTimeFormatter,
  Instant,
  IsoFields,
} from '@js-joda/core';

export function formatColumnHeader(
  date: DateInput,
  formatString: string,
  holidays: Holidays,
): string {
  const instant = Instant.ofEpochMilli(date.valueOf() as number);
  const localDate = LocalDate.ofInstant(instant);
  let kw = '';
  if (localDate.dayOfWeek().value() === 1) {
    kw = `KW ${localDate.get(IsoFields.WEEK_OF_WEEK_BASED_YEAR)}`;
  }
  const formatted = localDate.format(
    DateTimeFormatter.ofPattern(formatString).withLocale(JsJodaLocale.GERMANY),
  );
  let className = ``;
  const holiday = holidays.getAt(localDate);
  if (holiday.length > 0) {
    className = 'holiday';
  }
  return `
    <div class="resource-timegrid-header ${className}">
      <div class="week-header">
        ${kw}
      </div>
      <div class="day-header">
        ${formatted}
      </div>
    </div>
  `;
}

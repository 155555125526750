
export const userPoolId = process.env.REACT_APP_USER_POOL_ID;
export const identityPoolId = process.env.REACT_APP_IDENTITY_POOL_ID;
export const userPoolWebClientId = process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID;
export const oAuthDomain = process.env.REACT_APP_OAUTH_DOMAIN;
export const redirectUrl = process.env.REACT_APP_OAUTH_REDIRECT_URL;

// We need this guy to ensure we're always sending a customState to
// AWS Cognito UI. Otherwise the return hook 'customOAuthState' won't
// be called, but that is crucial for proper redirecting.
export const STOWAWAY = 'o7';

export const oauth = {
    domain: oAuthDomain,
    scope: ['aws.cognito.signin.user.admin'],
    redirectSignIn: redirectUrl,
    redirectSignOut: redirectUrl,
    responseType: 'code',
};
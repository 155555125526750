import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { ReferentDto } from '@hg-aka-prml/tapas-common';
import ReferentPicker from './ReferentPicker';
import useSelectedReferents from 'src/hooks/useSelectedReferents';

export type ReferentSearchForm = {
  referents: string[];
};

type Props = {
  allReferents: ReferentDto[];
  selectedReferentIds: string[];
  onFormChange: (form: ReferentSearchForm) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  searchGridStyles: {
    'min-height': '271px',
    '& .MuiGrid-container .MuiGrid-item': {
      marginBottom: theme.spacing(4),
    },
  },
}));

const ReferentSearchForm: React.FC<Props> = ({
  allReferents,
  selectedReferentIds,
  onFormChange,
}) => {
  const classes = useStyles();

  const selectedReferents: ReferentDto[] = useSelectedReferents(allReferents, selectedReferentIds);

  function handleReferentChange(
    event: React.ChangeEvent<unknown>,
    referents: ReferentDto[],
  ): void {
    if (onFormChange) {
      onFormChange({
        referents: referents.map((el: ReferentDto) => el.externalId.toString()),
      });
    }
  }

  return (
    <Paper
      square
      style={{ padding: '30px', position: 'relative', top: '-80px' }}
    >
      <Grid className={classes.searchGridStyles} container spacing={6}>
        <Grid item xs={12}>
          <Box mb={3}>
            <Typography variant="h3">Kapazitäten anzeigen</Typography>
          </Box>
          <ReferentPicker
            selectedReferents={selectedReferents}
            onChange={handleReferentChange}
            id="referent-picker"
            referents={allReferents}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ReferentSearchForm;

import { AsyncProps } from 'react-async';
import { ReferentDto } from '@hg-aka-prml/tapas-common';
import { IApiService } from 'src/api/service.interface';
import { ApiService } from 'src/api/service';
import { arrayMapper } from '@daniel-faber/json-ts';

export const loadReferent = async <T>(
  props: AsyncProps<T>,
  { signal }: AbortController,
): Promise<ReferentDto | null> => {
  if (props.authStatus === 'notAuthenticated') {
    // Return null if not authenticated
    return Promise.resolve(null);
  }
  const api: IApiService = ApiService;
  return api.get(`referents/${props.id}`, ReferentDto.fromJSON, { signal });
};

export const loadReferents = async <T>(
  props: AsyncProps<T>,
  { signal }: AbortController,
): Promise<ReferentDto[]> => {
  if (props.authStatus === 'notAuthenticated') {
    // Return empty list if not authenticated
    return Promise.resolve([]);
  }
  const api: IApiService = ApiService;
  return await api.get('referents', arrayMapper(ReferentDto.fromJSON), { signal });
};

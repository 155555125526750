import React, { ChangeEvent, Ref } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, FormLabel, FormControl, Input } from '@material-ui/core';

const useStyles = makeStyles({
  referentList: {
    '& .MuiAutocomplete-popupIndicator': {
      display: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiAutocomplete-inputRoot': {
      paddingLeft: 0,
    },
  },
  referentPicker: {
    '& .MuiAutocomplete-clearIndicator': {
      display: 'none',
    },
  },
});

type Props = {
  autoFocus: boolean;
  color: 'primary' | 'secondary' | undefined;
  defaultValue: string;
  disabled: boolean;
  error: boolean;
  fullWidth: boolean;
  id: string;
  InputLabelProps: object;
  inputProps: object;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  InputProps: Record<string, any>;
  label: string;
  name: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  required: boolean;
  type: string;
  value: string;
  variant: 'filled' | 'standard' | 'outlined' | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

const ReferentPickerInput = React.forwardRef(function ReferentPickerInput(
  props: Props,
  ref: Ref<HTMLFieldSetElement> | null,
) {
  const {
    autoFocus = false,
    color = 'primary',
    defaultValue,
    disabled = false,
    error = false,
    fullWidth = false,
    id,
    InputLabelProps,
    inputProps,
    InputProps,
    label,
    name,
    onChange,
    placeholder = 'Name',
    required = false,
    type,
    value,
    variant = 'standard',
    ...other
  } = props;

  const classes = useStyles();

  const inputLabelId = label && id ? `${id}-label` : undefined;
  return (
    <FormControl
      component="fieldset"
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      ref={ref}
      required={required}
      color={color}
      variant={variant}
      {...other}
    >
      <FormLabel
        id={inputLabelId}
        {...InputLabelProps}
        component="legend"
        style={{ marginBottom: '20px', textTransform: 'uppercase' }}
      >
        Referentenauswahl
      </FormLabel>
      <Input
        {...{ ...InputProps, startAdornment: undefined }} // no adornment (referent list)
        style={{
          border: '3px solid #2775E3',
          maxWidth: '400px',
          padding: '13px',
          marginBottom: '20px',
        }}
        disableUnderline={true}
        autoFocus={autoFocus}
        defaultValue={defaultValue}
        name={name}
        type={type}
        value={value}
        id={id}
        onChange={onChange}
        placeholder={placeholder}
        inputProps={inputProps}
        className={classes.referentPicker}
      />
      <TextField
        className={classes.referentList}
        InputProps={{
          ...InputProps,
          ref: undefined, // Don't uset this input element as anchorEl for popover
          style: {
            ...InputProps.style,
            padding: InputProps.startAdornment ? undefined : 0,
          },
        }}
        inputProps={{
          ...inputProps,
          id: undefined,
          style: {
            display: 'none', // No text input field
          },
        }}
        variant="outlined"
      />
    </FormControl>
  );
});

export default ReferentPickerInput;

import 'react-toastify/dist/ReactToastify.css';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import '@fullcalendar/timeline/main.css';
import '@fullcalendar/resource-timeline/main.css';

import '@js-joda/timezone';

import React from 'react';
import ReactDOM from 'react-dom';
import Amplify, { Auth } from 'aws-amplify';

import './styles.css';
import App from './components/App';
import { sendErrorLogMessage } from './api/serverLogger';
import { identityPoolId, oAuthDomain, oauth, userPoolWebClientId, redirectUrl, userPoolId } from "./authenticationInformation";


if (!userPoolId) {
  throw new Error('REACT_APP_USER_POOL_ID env variable is not defined!');
}
if (!userPoolWebClientId) {
  throw new Error(
    'REACT_APP_USER_POOL_WEB_CLIENT_ID env variable is not defined!',
  );
}
if (!oAuthDomain) {
  throw new Error('REACT_APP_OAUTH_DOMAIN env variable is not defined!');
}
if (!redirectUrl) {
  throw new Error(
    'REACT_APP_OAUTH_REDIRECT_URL env variable is not defined!',
  );
}
if (!identityPoolId) {
  throw new Error('REACT_APP_IDENTITY_POOL_ID env variable is not defined!');
}

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'eu-central-1',

    identityPoolId: identityPoolId,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: userPoolId,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: userPoolWebClientId,
  },
});

Auth.configure({ oauth });

ReactDOM.render(React.createElement(App), document.getElementById('root'));


try {
  window.onerror = (message, source, line, col): void => {
    sendErrorLogMessage(
      `${message}`,
      `window.onerror(source=${source}, line=${line}, col=${col})`,
    );
  };
} catch (e) {
  console.error(e.toString());
}

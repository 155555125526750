import { EventApi, View } from '@fullcalendar/core';
import { DateTimeFormatter, LocalDate, Instant, ZoneId } from '@js-joda/core';
import { Holidays } from 'src/holidays';
import { ReferentDto } from '@hg-aka-prml/tapas-common';
import CalendarEventBlock from '../components/CalendarEventBlock';
import ReactDOM from 'react-dom';
import React from 'react';

const titleFormat = {
  weekday: 'long',
  month: 'long',
  day: 'numeric',
  year: 'numeric',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const views = (): any => {
  return {
    resourceTimeGridDay: {
      type: 'resourceTimeGrid',
      duration: { days: 1 },
      slotDuration: '00:30:00',
      allDaySlot: false,
      slotEventOverlap: false,
      titleFormat: titleFormat,
    },
    resourceTimeGridWeek: {
      type: 'resourceTimeGrid',
      duration: { weeks: 1 },
      slotDuration: '00:30:00',
      allDaySlot: false,
      slotEventOverlap: false,
      titleFormat: titleFormat,
      // columnHeaderHtml: (date): string => formatColumnHeader(date, 'eee d')
    },
    resourceTimeGridMonth: {
      slotDuration: '00:30:00',
      type: 'resourceTimeGrid',
      duration: { months: 1 },
      buttonText: 'Monat',
      allDaySlot: false,
      slotEventOverlap: false,
      titleFormat: titleFormat,
      // columnHeaderHtml: (date): string => formatColumnHeader(date, 'eee d')
    },
  };
};

export const dayRenderer = (holidays: Holidays) => {
  return (arg: {
    view: View;
    date: Date;
    allDay?: boolean;
    el: HTMLElement;
  }): void => {
    let separator = false;
    let currentDay;
    for (const className of Array.from(arg.el.classList)) {
      if (
        [
          'fc-mon',
          'fc-tue',
          'fc-wed',
          'fc-thu',
          'fc-fri',
          'fc-sat',
          'fc-sun',
        ].includes(className)
      ) {
        currentDay = className;
      }
    }
    let previousDay;
    const prevSibling = arg.el.previousElementSibling;
    if (prevSibling) {
      for (const className of Array.from(prevSibling.classList)) {
        if (
          [
            'fc-mon',
            'fc-tue',
            'fc-wed',
            'fc-thu',
            'fc-fri',
            'fc-sat',
            'fc-sun',
          ].includes(className)
        ) {
          previousDay = className;
        }
      }
    }
    if (currentDay !== previousDay) {
      separator = true;
    }

    const instant = Instant.ofEpochMilli(arg.date.valueOf() as number);
    const localDate = LocalDate.ofInstant(instant);
    const holiday = holidays.getAt(localDate);
    if (holiday.length > 0) {
      arg.el.className += ' holiday';
    }
    if (separator) {
      arg.el.className += ' sep';
    }
  };
};

export const dayClickHandler = (
  setHolidayPopoverAnchorDate: Function,
  holidays: Holidays,
) => {
  return (date: Date, ev: Event): void => {
    let el: EventTarget | null = ev.target;
    if (el instanceof HTMLElement) {
      // ** Horrible HACK **
      // Fullcalendar.io does not play nicely with React :-(
      //
      // We can't use curentTarget (HTMLElement) as state
      // variable, because the next render pass will invalidate it,
      // because FullCalendar.io destroys DOM elements when
      // calling formatColumnHeader (e.g. it updates the DOM via el.innerHTML=formatColumnHeader())
      // Instead we need to remember the data-goto attribute which Fullcalendar renders to the DOM
      // to identify the cell later on when we want to position the popover.
      // The format is as follows:
      //
      //     data-goto="{"date":"2020-02-07","type":"day"}"
      //
      while (el && el instanceof HTMLElement) {
        if (el.dataset.goto) {
          const parsed = JSON.parse(el.dataset.goto);
          const date: LocalDate = LocalDate.parse(parsed.date);
          if (holidays.getAt(date).length > 0) {
            setHolidayPopoverAnchorDate(parsed.date);
            break;
          }
        }
        el = el.parentElement;
      }
    }
  };
};

// Render function to render event blocks
export const eventRender = (referents: ReferentDto[], activeView: string) => {
  return ({
    event,
    el,
    isStart,
  }: {
    isStart: boolean;
    event: EventApi;
    el: HTMLElement;
  }) => {
    const referent = referents.find(el => {
      if (el.externalId === parseInt(event.getResources()[0].id, 10)) {
        return true;
      }
      return false;
    });
    let initials = '';
    initials += referent?.firstname ? referent.firstname[0].toUpperCase() : '';
    initials += referent?.lastname ? referent.lastname[0].toUpperCase() : '';

    const formatter = DateTimeFormatter.ofPattern('HH:mm');

    const instantStart = event.start
      ? Instant.ofEpochMilli(event.start.valueOf() as number)
      : null;
    const startTime = instantStart
      ? instantStart.atZone(ZoneId.UTC).format(formatter)
      : '';
    const instantEnd = event.end
      ? Instant.ofEpochMilli(event.end.valueOf() as number)
      : null;
    const endTime = instantEnd
      ? instantEnd.atZone(ZoneId.UTC).format(formatter)
      : '';
    const monthNames = ["Januar", "Februar", "März", "April", "Mai", "Juni",
      "Juli", "August", "September", "Oktober", "November", "Dezember"
      ];
    const weekdayNames = ['So','Mo','Di','Mi','Do','Fr','Sa'];
    const date = event.start
      ? weekdayNames[event.start.getDay()] + '. ' + event.start.getDate().toString() + '. ' + monthNames[event.start.getMonth()] + ' ' + event.start.getFullYear().toString() : '';

    el.classList.add('fc-event-' + event.extendedProps.status);
    if (event.extendedProps.inquiryAnswer) {
      el.classList.add('fc-event-inquiryAnswer-' + event.extendedProps.inquiryAnswer.status);
      el.classList.add('fc-event-inquiryAnswer-' + (event.extendedProps.inquiryAnswer.valid ? 'valid' : 'invalid'));
    }

    // Non-standard fields like the bookingNumber, contactPerson ... are moved into the
    // extendedProps hash during event parsing (see https://fullcalendar.io/docs/event-object)
    return ReactDOM.render(
      <CalendarEventBlock
        activeView={activeView}
        info={{
          eventId: '',
          initials: initials,
          bookingNumber: event.extendedProps.bookingNumber,
          businessDivision: event.extendedProps.businessDivision,
          contactPerson: event.extendedProps.contactPerson,
          location: event.extendedProps.location,
          locationId: event.extendedProps.locationId,
          startTime,
          endTime,
          title: event.title,
          referentName: `${referent?.title || ''} ${referent?.firstname} ${
            referent?.lastname
          }`,
          customer: event.extendedProps.customer,
          customerNumber: event.extendedProps.customerNumber,
          region: event.extendedProps.region,
          status: event.extendedProps.status,
          date: date,
          inquiryAnswer: event.extendedProps.inquiryAnswer,
          blockingCreationDate: event.extendedProps.blockingCreationDate,
        }}
      />,
      el,
    );
  };
};
